import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import Cookie from 'js-cookie';

export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}
export interface Account {
  id: number;
  username: string;
  avatar: string;
  gender: number;
  is_superuser?: boolean;
  email?: string;
  name?: string;
  company_name?: string;
}

export type TokenResult = {
  token: string;
  expires: number;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true,
    };
  },
  actions: {
    async login(username: string, password: string) {
      return http
        .request<TokenResult, Response<TokenResult>>('/account/login', 'post_json', { username, password })
        .then(async (response) => {
          if (response.code === 0) {
            this.logged = true;
            const expires = new Date(response.data.account.expires);
            http.setAuthorization(`JWT ${response.data.token}`, expires);
            // await useMenuStore().getMenuList();
            const accountId = response.data.account.id;
            Cookie.set("SapientiaAccountId", accountId, { expires });
            return response.data;
          } else {
            return Promise.reject(response);
          }
        });
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        http.removeAuthorization();
        this.logged = false;
        resolve(true);
      });
    },
    async profile() {
      return http.request<Account, Response<Profile>>('/account/auth', 'get').then((response) => {
        if (response.code === 0) {
          const { setAuthorities } = useAuthStore();
          const { account, permissions, role } = response.data;
          this.account = account;
          this.permissions = permissions;
          this.role = role;
          const expires = new Date(account.expires);
          Cookie.set("SapientiaAccountRU", account.remaining_usage, { expires });
          Cookie.set("SapientiaAccountExpireDate", account.expire_date, { expires });

          const {access_token, refresh_token} = account.dify_token;
          localStorage.setItem('console_token', access_token);
          localStorage.setItem('refresh_token', refresh_token);

          console.log(response.data);
          setAuthorities(permissions);
          return response.data;
        } else {
          return Promise.reject(response);
        }
      });
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
  },
});
